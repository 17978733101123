<script setup lang="ts">
import AuthLayout from '../../layouts/AuthLayout.vue';
import { Link, useForm, usePage } from '@inertiajs/vue3';
import { route } from 'ziggy-js';
import { AuthPageProps } from '../../index';

const form = useForm({
  email: '',
  password: '',
  remember: false,
});

const page = usePage<AuthPageProps>();

function submit() {
  form
    .transform((data) => ({
      ...data,
      remember: form.remember ? 'on' : '',
    }))
    .post(route('login'), {
      onFinish: () => form.reset('password'),
    });
}
</script>

<template>
  <AuthLayout :title="$t('auth.login')">
    <VForm :disabled="form.processing" @submit.prevent="submit">
      <VCardItem>
        <VAlert v-if="page.props.status" type="success">{{ page.props.status }}</VAlert>
        <VCardText class="text-center">{{ $t('auth.welcome') }}</VCardText>
        <VTextField
          v-model="form.email"
          :error-messages="form.errors.email"
          :label="$t('auth.field.email')"
          autofocus
          type="email"
          required
        />
        <VTextField
          v-model="form.password"
          :error-messages="form.errors.password"
          :label="$t('auth.field.password')"
          type="password"
          required
        />
        <VCardText class="text-center">
          <Link :href="route('password.request')">{{ $t('auth.forgotPassword.title') }}</Link>
        </VCardText>
        <VCheckbox
          v-model:checked="form.remember"
          :error-messages="form.errors.remember"
          :label="$t('auth.field.rememberMe')"
          hide-details="auto"
        />
      </VCardItem>
      <VCardActions>
        <VBtn :loading="form.processing" block color="primary" type="submit">
          {{ $t('auth.login') }}
        </VBtn>
      </VCardActions>
    </VForm>
  </AuthLayout>
</template>
